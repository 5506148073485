
import { Options, Vue } from 'vue-class-component';
import RandomSentence from './components/RandomSentence.vue';
import SentenceParts from './data/sentence-parts';

@Options({
  components: {
    RandomSentence,
  },
  data() {
    return {
      parts: SentenceParts,
      random: Math.random(),
    }
  },
  methods: {
    shuffle() {
      this.random = Math.random();
    }
  }
})
export default class App extends Vue {}
