import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RandomSentence = _resolveComponent("RandomSentence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RandomSentence, {
      parts: _ctx.parts,
      random: _ctx.random
    }, null, 8, ["parts", "random"]),
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.shuffle && _ctx.shuffle(...args)))
    }, " Meer bullshit ")
  ]))
}