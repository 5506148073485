
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
    props: {
        parts: {
            type: Array as PropType<String[][]>,
            required: true,
        },
        random: {
            type: Number,
            default: () => {
                return Math.random();
            },
        },
    },
    setup(props) {
        const randomParts = computed(() => {
            return props.parts.map((part, index) => {
                const localRandom = index ? Math.random() : props.random;
                return part[Math.floor(localRandom * part.length)];
            });
        });
        return { randomParts };
    },
})
